
import Vue from 'vue';

  export default Vue.extend({
    name: 'ComponentCopyToFloor',
    props: ['visible', 'floorId'],
    data() {
      return {
        visible2: this.visible, // 希望父组件能用.sync修饰符，所以要用visible2做“中转”
        checkedFloor: [],
        floorList: [],
        checkComIdList: [],
      };
    },
    watch: {
      visible() {
        this.visible2 = this.visible;
      },
      visible2() {
        this.$emit('update:visible', this.visible2);
      },
    },
    created() {
      this.floorList = this.$store.state.buildingModule.floorList;
    },
    methods: {
      finish() {
        this.$emit('finish', this.checkComIdList);
      },
      handleCheckedFloorChange(e) {
        this.checkComIdList = e;
      },
    },
  });


import Vue, { PropType } from 'vue';

  export default Vue.extend({
    name: 'ComponentAppend',
    props: {
      component: {
        type: Object as PropType<any>,
        required: true,
      },
    },
    data() {
      return {
        formLabelWidth: '70px',
        form: {
          name: '',
          qsCategory: 0,
          qsTypeTable: 0,
        },
      };
    },
    computed: {
      secondTree(): any {
        return this.$store.state.operand.categorySecondTree;
      },
      // 类型列表
      qsTypeList(): any {
        if (this.secondTree.length === 0) {
          return [];
        }
        return this.secondTree.find((item) => item.category.code === this.form.qsCategory).category.nodes;
      },
    },
    async mounted() {
      this.secondTree.forEach((item) => {
        const { nodes } = item.category;
        nodes.forEach((i) => {
          if (i.code === this.component.qsTypeTable) {
            this.form.qsCategory = item.category.code;
          }
        });
      });
      this.form.qsTypeTable = this.component.qsTypeTable;
    },
    methods: {
      async editCom() {
        await this.$store.dispatch('addComponent', this.form);
        this.$emit('update:component', null);
      },
      cancel() {
        this.$emit('update:component', null);
      },
    },
  });


  import Vue from 'vue';
  import {
    putReinforQuanti,
  } from '@/api/rebarSetting';
  import { mapGetters } from 'vuex';

  export default Vue.extend({
    name: 'QuantityReinforce',
    props: ['val', 'searchVal', 'activeC', 'hintBg', 'reinforcCountt'], // todo: 这怎么重构？？
    data() {
      return {
        tableDataOne: [] as any[],
        headers: [] as any[],
        name: '',
        secondTh: [] as any[],
        cells: [] as any[],
        tableB: [] as any[],
        tableS: [] as any[],
        loading: true,
        pageSize: 0,
        tierList: [] as any[],
        allRows: {} as any,
      };
    },
    computed: {
      ...mapGetters({
        buildingId: 'buildingId',
      }),
    },
    created() {
      this.putReinforQuanti();
    },
    watch: {
      val() {
        this.putReinforQuanti();
      },
      reinforcCountt(val) {
        val && (this as any).$print(this.$refs.reinforRebar);
      },
      hintBg(val) {
        this.tableDataOne.find((element) => { // 提示高亮
          const td = element.cells.find((e) => e.peggable);
          td.bg = val;
          return td;
        });
      },
    },
    methods: {
      filterKeyLight(text) { // 过滤高亮关键字
        if (text === null || text === undefined) {
          text = '';
        } else {
          text = text.toString();
        }
        if (!this.searchVal) return text;
        const result = text.replace(new RegExp(this.searchVal, 'g'), `<span style="background-color:#fcc360;">${this.searchVal}</span>`);
        return result;
      },
      async putReinforQuanti() { // 钢筋
        this.secondTh = [];
        this.tableB = [];
        this.tableS = [];
        const { reportId } = this.val;
        const option = {
          reportId,
          buildingId: this.buildingId,
          floorNames: this.val.floorNames,
          usages: this.val.usages,
          qsTypes: this.val.type,
          reportScopes: this.val.reportScopes,
        };
        this.loading = true;
        const res = await putReinforQuanti(option);
        this.loading = false;
        if (reportId !== this.val.reportId) return;
        res.rows.forEach((element) => { // 默认没选中
          element.current = false;
          element.cells.forEach((d) => { // 子级可以选中的
            d.current = false;
            if (d.peggable) {
              d.bg = false;
            }
          });
        });
        res.rows.forEach((r) => {
          r.toggle = true;
        });
        const data = JSON.parse(JSON.stringify(res.rows));
        this.tableDataOne = data;
        this.allRows = JSON.parse(JSON.stringify(res));
        this.$emit('initialData', this.allRows); // 导出excel的值
        // this.tableDataOne = res.data.rows.concat(data.concat(data.concat(data.concat(data.concat(data.concat(data.concat(data.concat(data))))))));
        this.headers = res.headers;
        if (this.tableDataOne.length === 0) {
          this.tableB = [];
        } else if (this.tableDataOne.length > 0) {
          this.pageSize = 1;
          const a = 23 - this.tableDataOne.length;
          let cells: any[] = [];
          let b = 0;
          this.headers.forEach((r) => {
            if (r.subHeaders === null) {
              b += 1;
            } else if (r.subHeaders !== null) {
              b += r.subHeaders.length;
            }
          });
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < a; i++) {
            cells = [];
            // eslint-disable-next-line no-plusplus
            for (let r = 0; r < b; r++) {
              cells.push({ value: '', rowSpan: 1, colSpan: 1 });
            }
            this.tableDataOne.push({ cells });
          }
          this.pageSize = Math.ceil(this.tableDataOne.length / 23);
          this.tableB.push(this.tableDataOne);
        }
        this.headers.forEach((r) => {
          if (r.subHeaders !== null) {
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < r.subHeaders.length; i++) {
              this.secondTh.push(r.subHeaders[i]);
            }
          }
        });
      },
      choose(tr, idx, td, tdIdx, dblclick) { // 反查模型选中高亮
        if (!td.peggable
          || this.val.code === 'RebarTypeAndDiameter'
          || this.val.code === 'RebarFloorAndType') { return false; }
        let rowSpanVal = 1; // 跨行
        tr.cells.forEach((d) => {
          if (d.rowSpan > 1 && d.peggable) {
            rowSpanVal = d.rowSpan;
          }
        });
        if (rowSpanVal) {
          this.tableDataOne.forEach((e, i) => {
            if (i >= idx && i <= idx + rowSpanVal - 1) {
              e.cells.forEach((d, didx) => {
                if (rowSpanVal === 1) {
                  if (didx >= tdIdx) {
                    d.current = true;
                  }
                } else {
                  d.current = true;
                }
              });
            } else {
              e.cells.forEach((d) => {
                d.current = false;
              });
            }
          });
        }
        this.$emit('checkModel', { data: td.conditions, dblclick });
        return true;
      },
    },
  });


  import Vue from 'vue';
  import {
    getRebarUsageCategory,
  } from '@/api/project';
  import { getFloorOptions } from '@/api/floor';
  import { mapGetters } from 'vuex';

  export default Vue.extend({
    name: 'QuantitySelectFloor',
    props: { selectFloorVisible: Boolean },
    data() {
      return {
        floor: {
          checkedIds: [],
          checkAll: true,
          isIndeterminate: false,
          data: [] as any[],
          checkedCount: 0,
          defaultProps: {
            children: 'nodes',
            label: 'name',
          },
        },
        type: {
          checkedIds: [],
          checkAll: true,
          isIndeterminate: false,
          data: [] as any[],
          checkedCount: 0,
          defaultProps: {
            children: 'nodes',
            label: 'name',
          },
        },
        checkedBottomData: [] as any[],
        bottomData: [] as any[],
        checkedslData: [1],
        bottomslData: [{
          label: '汇总工程量',
          val: 1,
        }, {
          label: '模型算量',
          val: 2,
        }, {
          label: '表格算量',
          val: 3,
        }],
      };
    },
    computed: {
      ...mapGetters({
        buildingId: 'buildingId',
      }),
    },
    watch: {
      selectFloorVisible(val) {
        if (val && this.floor.data.length === 0 && this.type.data.length === 0 && this.bottomData.length === 0) {
          this.initialize();
        }
      },
    },
    methods: {
      async initialize() {
        this.type.data = await this.$api.Quantity.getAllCategories(); // 获取构件类型
        this.$nextTick(() => {
          this.allChange(true, 'type');
          this.type.checkedCount = (this.$refs.type as any).getCheckedKeys().length;
        });
        const res = await getFloorOptions({ buildingId: this.buildingId }); // 获取数据
        this.floor.data = res as any;
        this.$nextTick(() => {
          this.allChange(true, 'floor');
          this.floor.checkedCount = (this.$refs.floor as any).getCheckedKeys().length;
        });
        const data = await getRebarUsageCategory(); // 获取字典枚举
        this.bottomData = data;
        this.checkedBottomData = data.map((e) => e.val);
      },
      handleClose() { // 关闭界面
        this.$emit('selectFloorColse', false);
      },
      allChange(val, name) {
        if (val) {
          (this.$refs[name] as any).setCheckedNodes(this[name].data);
        } else {
          (this.$refs[name] as any).setCheckedNodes([]);
        }
        this[name].isIndeterminate = false;
      },
      handleCheckChange(name) { // 单选
        const checkedCount = (this.$refs[name] as any).getCheckedKeys().length;
        this[name].checkAll = checkedCount === this[name].checkedCount;
        this[name].isIndeterminate = checkedCount > 0 && checkedCount < this[name].checkedCount;
      },
      handleSave() {
        const getCheckedFloor = (this.$refs.floor as any).getCheckedNodes().map((e) => e.name);
        const floorNums = (this.$refs.floor as any).getCheckedKeys();
        const type = (this.$refs.type as any).getCheckedNodes().map((e) => e.val);
        this.$emit('selectScope', getCheckedFloor, this.checkedBottomData, type, this.checkedslData, floorNums);
      },

    },
  });


import Vue from 'vue';
  import { postfloorartcopy, deleteElements } from '@/api/floorSet';
import { Tree } from 'element-ui';
import { ElInput } from 'element-ui/types/input';
  import ComponentListFloor from './ComponentListFloor.vue';
  import ComponentEdit from './ComponentEdit.vue';
  import ComponentAppend from './ComponentAppend.vue';
  import ComponentCopyToFloor from './ComponentCopyToFloor.vue';

  export default Vue.extend({
    name: 'ComponentList',
    components: {
      ComponentEdit,
      ComponentAppend,
      ComponentListFloor,
      ComponentCopyToFloor,
    },
    props: [],
    data() {
      return {
        // UI控制相关变量
        startNode: null as any,
        popoverCurrentData: null as any,
        popoverMenuTimer: null as any,
        currentRenameDataId: null as any, // 双击重命名
        currentEditData: null as any,
        currentAppendData: null as any,
        copyToFloorVisible: false,
        lastEnterNodeId: 0, // 当鼠标移入弹出菜单时，“更多”按钮也要高亮，这个比较坑

        // 逻辑相关变量
        selectedNodes: [] as any[],
      };
    },
    computed: {
      currentFloor(): any {
        return this.$store.state.operand.currentFloor;
      },
      projectReadonly(): boolean {
        return this.$store.getters.projectReadonly;
      },
      componentsTreeData(): any {
        return this.$store.state.operand.componentsTree;
      },
      // 将树扁平化/正规化
      flatNodes(): any {
        const res: any[] = [];
        // 深搜才能确保次序稳定
        function nodeFlat(nodeOrArr) {
          if (Array.isArray(nodeOrArr)) {
            nodeOrArr.forEach((v) => nodeFlat(v));
          } else {
            res.push(nodeOrArr);
            if (nodeOrArr.elements) {
              nodeFlat(nodeOrArr.elements);
            }
          }
        }
        nodeFlat(this.componentsTreeData);
        return res;
      },
      flatLeaves(): any[] {
        return this.flatNodes.filter((v) => v.isLeaf);
      },
      selectedLeaves(): any[] {
        return this.selectedNodes.filter((node) => node.isLeaf);
      },
    },
    watch: {
      componentsTreeData() {
        this.updateSelected();
      },
      selectedLeaves() {
        this.updateUI();
      },
      startNode(newNode) {
        this.$store.dispatch('selectComponent', newNode);
      },
    },
    methods: {
      updateSelected() {
        if (this.flatLeaves.length === 0) {
          this.startNode = null;
          this.selectedNodes = [];
        } else if (this.flatLeaves.length > 0) {
          if (!this.startNode || !this.flatLeaves.find((v) => v.id === this.startNode.id)) {
            // 坑：必须选中一个，不能不选
            this.startNode = this.flatLeaves[0];
            this.selectedNodes = [this.startNode];
          }
        }
        this.updateUI();
      },
      updateUI() {
        this.$nextTick(() => {
          (this.$refs.tree as Tree).setCheckedNodes(this.selectedLeaves, true);
          if (!this.startNode) {
            (this.$refs.tree as Tree).setCurrentKey(null);
          } else {
            (this.$refs.tree as Tree).setCurrentNode(this.startNode);
          }
        });
      },
      renameComponent(data) {
        if (!data.isLeaf) return;
        this.currentRenameDataId = data.id;
        // 等输入框在下一个tick出现的时候focus
        this.$nextTick(() => {
          (this.$refs.input as ElInput).focus();
        });
      },
      editComponent(data) {
        this.currentEditData = data;
      },
      copyGroup(data) {
        this.selectedNodes = data.elements;
        this.copyToFloorVisible = true;
      },
      appendComponent(data) {
        this.currentAppendData = { qsTypeTable: data.qsTypeTable };
      },
      deleteGroup(data) {
        this.selectedNodes = data.elements;
        this.doDelete(data.elements);
      },
      unselectId(idList) {
        if (!this.startNode) return;
        if (idList.includes(this.startNode.id)) {
          this.startNode = null;
        }
        this.selectedNodes = this.selectedNodes.filter((v) => !idList.includes(v.id));
      },
      async doRenameComponent(e) {
        await this.doEditComponent(e);
        this.currentRenameDataId = null;
      },
      async doEditComponent(data) {
        if (!data.name) {
          this.$message({
            message: '构件名不能为空',
            type: 'error',
          });
        } else {
          try {
            await this.$store.dispatch('editComponent', data);
            this.$message({
              message: '修改成功',
              type: 'success',
            });
            this.currentEditData = null;
          } catch (e) {
            this.$message({
              message: e.message,
              type: 'error',
            });
          }
        }
      },
      async doDelete(data) {
        if (!this.startNode) return;
        let idList;
        if (!data) {
          data = this.startNode;
        } if (Array.isArray(data)) {
          idList = data.map((v) => v.id);
        } else {
          idList = [data.id];
          this.$message({
            message: '可按住shift多选构件',
            type: 'info',
          });
        }
        setTimeout(() => {
          this.$confirm('删除构件后不可恢复，是否确定删除？', '确定要删除构件吗?', { type: 'warning' })
            .then(async () => {
              await deleteElements(idList);
              this.$message({ message: '删除成功', type: 'success' });
              await this.$store.dispatch('fetchComponents');
              this.unselectId(idList);
            });
        }, 400);
      },
      async doCopyToFloor(e) {
        const option = {
          targetFloorIds: e,
          floorModelElementIds: this.selectedLeaves.map((v) => v.id),
        };
        try {
          await postfloorartcopy(option);
          this.$message({ message: '复制成功', type: 'success' });
        } finally {
          this.copyToFloorVisible = false;
        }
      },
      async copyComponent(data) {
        await this.$store.dispatch('copyComponent', data);
        this.$message({ message: '复制成功', type: 'success' });
      },
      onClick(node) {
        if (!node.isLeaf) return;
        this.startNode = node;
        this.selectedNodes = [node];
      },
      onShiftClick(node) {
        if (this.startNode) {
          const endNode = node;
          let startIndex = this.flatNodes.indexOf(this.startNode);
          let endIndex = this.flatNodes.indexOf(endNode);
          if (startIndex > endIndex) [startIndex, endIndex] = [endIndex, startIndex];
          this.selectedNodes = this.flatNodes.slice(startIndex, endIndex + 1);
        } else {
          this.startNode = node;
        }
      },
      onCtrlClick(node) {
        const idx = this.selectedNodes.indexOf(node);
        if (idx === -1) {
          this.selectedNodes.push(node);
        } else {
          // 坑：如果只剩下这一个叶子节点，不能取消，因为Excel组件要求必须至少选一个
          if (this.selectedLeaves.length === 1) {
            return;
          }
          this.selectedNodes.splice(idx, 1);
          if (node === this.startNode) {
            // 坑：如果取消的是startNode，startNode更新为第一个叶子节点
            this.startNode = this.selectedLeaves[0];
          }
        }
      },
      onNodeClick() {
        // 覆盖Element-UI的默认行为
        this.updateUI();
      },
      enterPopoverMenuToggle(flag, event, data, menu) {
        this.popoverCurrentData = data;
        if (this.popoverMenuTimer) clearTimeout(this.popoverMenuTimer);
        this.lastEnterNodeId = data.id;
        if (flag) {
          (this.$refs.popoverMenu as any).style.display = 'none';
          (this.$refs.popoverMenu as any).style.display = 'none';
          // 显示弹出菜单
          menu.style.display = 'block';
          const toggleRect = event.target.getBoundingClientRect();
          const menuRect = menu.getBoundingClientRect();
          menu.style.left = `${(toggleRect.left + toggleRect.right) / 2 - menuRect.width / 2}px`;
          if (toggleRect.bottom + menuRect.height > window.innerHeight) {
            menu.style.top = `${toggleRect.top - menuRect.height}px`;
          } else {
            menu.style.top = `${toggleRect.bottom}px`;
          }
        } else {
          this.popoverMenuTimer = setTimeout(() => {
            menu.style.display = 'none';
            this.lastEnterNodeId = 0;
          }, 100);
        }
      },
      enterPopoverMenu(flag, event) {
        if (flag) {
          clearTimeout(this.popoverMenuTimer);
          event.target.style.display = 'block';
        } else {
          this.popoverMenuTimer = setTimeout(() => {
            event.target.style.display = 'none';
            this.lastEnterNodeId = 0;
          }, 100);
        }
      },
    },
  });

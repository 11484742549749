
  import Vue from 'vue';
  import {
    putReinforQuantiDetails,
  } from '@/api/rebarSetting';
  import { mapGetters } from 'vuex';

  export default Vue.extend({
    name: 'quantityReinforceDetails',
    props: ['val', 'searchVal', 'activeC', 'reinforcCountt'], // todo: 这怎么重构？？
    data() {
      return {
        tableDataOne: [] as any[],
        headers: [] as any[],
        name: '',
        secondTh: [] as any[],
        cells: [] as any[],
        loading: true,
        tierList: [] as any[],
        allRows: {} as any,
        noMore: false,
        currentPage: undefined,
        init: true,
      };
    },
    computed: {
      ...mapGetters({
        buildingId: 'buildingId',
      }),
    },
    created() {
      this.putReinforQuanti();
    },
    watch: {
      val() {
        this.putReinforQuanti();
      },
      reinforcCountt(val) {
        val && (this as any).$print(this.$refs.reinforRebar);
      },
    },
    methods: {
      // 加载更多
      loadMore() {
        if (!this.noMore && this.tableDataOne.length > 0) {
          this.putReinforQuanti();
        }
      },
      filterKeyLight(text) { // 过滤高亮关键字
        if (text === null || text === undefined) {
          text = '';
        } else {
          text = text.toString();
        }
        if (!this.searchVal) return text;
        const result = text.replace(new RegExp(this.searchVal, 'g'), `<span style="background-color:#fcc360;">${this.searchVal}</span>`);
        return result;
      },
      async putReinforQuanti() { // 钢筋
        this.init = !this.currentPage;
        this.secondTh = [];
        const { reportId } = this.val;
        const option = {
          reportId,
          buildingId: this.buildingId,
          floorNames: this.val.floorNames,
          floorNums: this.val.floorNums,
          usages: this.val.usages,
          qsTypes: this.val.type,
          reportScopes: this.val.reportScopes,
          currentPage: this.currentPage,
        };
        this.loading = true;
        const res = await putReinforQuantiDetails(option);

        if (reportId !== this.val.reportId) return;
        this.loading = false;
        this.currentPage = res.currentPage;
        this.noMore = res.rows.length === 0;
        if (this.init) this.$emit('currentTab', null, res.rows.length);
        res.rows = this.tableDataOne.concat(res.rows);
        const data = JSON.parse(JSON.stringify(res.rows));
        this.tableDataOne = data;
        this.allRows = JSON.parse(JSON.stringify(res));
        if (this.init) this.headers = res.headers;
        if (this.tableDataOne.length > 0 && this.noMore) {
          const a = 23 - this.tableDataOne.length;
          let cells: any[] = [];
          let b = 0;
          this.headers.forEach((r) => {
            if (r.subHeaders === null) {
              b += 1;
            } else if (r.subHeaders !== null) {
              b += r.subHeaders.length;
            }
          });
          for (let i = 0; i < a; i++) {
            cells = [];
            for (let r = 0; r < b; r++) {
              cells.push({ value: '', rowSpan: 1, colSpan: 1 });
            }
            this.tableDataOne.push({ cells });
          }
        }
        if (!this.init) return;
        this.headers.forEach((r) => {
          if (r.subHeaders !== null) {
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < r.subHeaders.length; i++) {
              this.secondTh.push(r.subHeaders[i]);
            }
          }
        });
      },
      choose(tr, idx, td, tdIdx, dblclick) { // 反查模型选中高亮
        if (!td.peggable
          || this.val.code === 'RebarTypeAndDiameter'
          || this.val.code === 'RebarFloorAndType') { return false; }
        let rowSpanVal = 1; // 跨行
        tr.cells.forEach((d) => {
          if (d.rowSpan > 1 && d.peggable) {
            rowSpanVal = d.rowSpan;
          }
        });
        if (rowSpanVal) {
          this.tableDataOne.forEach((e, i) => {
            if (i >= idx && i <= idx + rowSpanVal - 1) {
              e.cells.forEach((d, didx) => {
                if (rowSpanVal === 1) {
                  if (didx >= tdIdx) {
                    this.$set(this.tableDataOne[i].cells[didx], 'current', true);
                  }
                } else {
                  this.$set(this.tableDataOne[i].cells[didx], 'current', true);
                }
              });
            } else {
              e.cells.forEach((d, didx) => {
                this.$set(this.tableDataOne[i].cells[didx], 'current', false);
              });
            }
          });
        }
        this.$emit('checkModel', { data: td.conditions, dblclick });
        return true;
      },
    },
  });


  import Vue from 'vue';
  import Bus from '@/bus.js';
  import { getjointslpicNew, TableProperties } from '@/api/floorSet';
  import RebarDrawing from '@/components/drawing/RebarDrawing';
  import DrawingOptions from '@/components/drawing/DrawingOptions';

  export default Vue.extend({
    name: 'MeasurementGraphical',
    data() {
      return {
        elementId: '',
        value: '',
        graphicalList: [] as any[],
        treeDatajdtj: [] as any[],
        defaultPropsjdtj: [] as any[],
        imgurllist: ['/cdn/atlas/table/LouTi/AT/AT.png'],
        propertyData: [] as any[],
        // floorId: '',
        selectedNodeAtlasValue: '',
        properties: [] as any[],
        expandedkeys: [] as any[],
        currentNodekey: '',
        height: 0,
        width: 0,
        rebarDrawing: null as any,
        hold: false,
      };
    },
    computed: {
      currentComponent(): any {
        return this.$store.state.operand.currentComponent;
      },
      floorId(): any {
        return this.$store.state.operand.currentFloor.floorId;
      },
    },
    mounted() {
      Bus.$on('getjointslpic', () => {
        this.$nextTick(() => {
          this.height = (document.getElementById('listingpicright') as any).offsetHeight;
          this.width = (document.getElementById('listingpicright') as any).offsetWidth;
          this.getjointslpicNew();
        });
      });
      window.onresize = () => {
        this.height = (document.getElementById('listingpicright') as any).offsetHeight;
        this.width = (document.getElementById('listingpicright') as any).offsetWidth;
        this.rebarDraw();
      };
    },
    watch: {
      currentComponent(val) {
        this.elementId = val?.modelElementId;
        this.getjointslpicNew();
        if (this.hold) {
          this.TableProperties();
          this.hold = false;
        }
      },
    },
    methods: {
      handleNodeClickjdtj(e) {
        this.imgurllist = e.imageUrls;
        this.propertyData = e.groups;
        this.selectedNodeAtlasValue = e.value;
        this.rebarDraw();
      },
      // 钢筋图集展示
      rebarDraw() {
        if (!this.rebarDrawing) {
          const option = new DrawingOptions('picZoom');
          option.config.width = this.width;
          option.config.height = this.height;
          option.dragButton = 1;
          option.enablePadding = false;
          option.enableYAxisFlip = false;
          option.mode = 2;
          this.rebarDrawing = new RebarDrawing(option);
        } else {
          this.rebarDrawing.size({ width: this.width, height: this.height });
        }
        this.rebarDrawing.loadImage({ position: { x: 0, y: 0 }, size: { x: this.width, y: this.height }, image: this.imgurllist[0] });
      },
      async getjointslpicNew() {
        const res = await getjointslpicNew(this.elementId);
        if (res.nodeAtlasList) {
          res.nodeAtlasList.forEach((r) => {
            if (r.childNodes) {
              r.childNodes.forEach((p) => {
                if (p.groups) {
                  p.groups.forEach((m) => {
                    m.toggle = true;
                  });
                }
              });
            }
          });
        }
        this.graphicalList = res.nodeAtlasList;
        this.value = this.graphicalList[0].name;
        this.treeDatajdtj = this.graphicalList[0].childNodes;
        this.currentNodekey = this.treeDatajdtj[res.selectedNodeAtlasValue === 0 ? 0 : res.selectedNodeAtlasValue - 1].value;
        this.$nextTick(() => {
          (this.$refs.treejd as any).setCurrentKey(this.currentNodekey);
        });
        this.handleNodeClickjdtj(this.treeDatajdtj[0]);
      },
      graphical(e) {
        this.graphicalList.forEach((r) => {
          if (r.name === e) {
            this.treeDatajdtj = r.childNodes;
          }
        });
      },
      togglee(item) {
        item.toggle = !item.toggle;
      },
      // OCR图片放大缩小功能(所有图片div通用，通过jQuery的id绑定操作)
      imgToSize(imgId, event) {
        // 通过这个值与零的大小比较，可以知道鼠标的滚轮滚动的方向
        const { deltaY } = event;
        let size = 0;
        if (deltaY > 0) {
          size = -100;
        } else if (deltaY < 0) {
          size = 100;
        }
        const img = (window as any).$(`#${imgId.id}`);
        // 取得图片的实际宽度
        const oWidth = img.width();
        // 取得图片的实际高度
        const oHeight = img.height();
        // 赋予改变后的值
        img.width(oWidth + size);
        img.height(oHeight + (size / oWidth) * oHeight);
      },

      // 鼠标左键按下，拖动照片事件(所有图片div通用，通过jQuery的id绑定操作)
      mousedown(id) {
        // width: 570, height: 300,需与div的大小一致
        (window as any).$(`#${id}`).imageView({ width: 570, height: 300 });
      },
      // 插入数据
      async TableProperties() {
        if (!this.elementId) {
          this.hold = true;
          this.$parent?.$emit('addElement');
          return;
        }
        this.properties = [];
        this.propertyData.forEach((r) => {
          r.properties.forEach((p) => {
            this.properties.push({
              keyword: p.keyword,
              value: p.value,
            });
          });
        });
        const option = {
          floorId: this.floorId,
          elementId: this.elementId,
          selectedNodeAtlasValue: this.selectedNodeAtlasValue,
          properties: this.properties,
        };
        await TableProperties(option);
        this.$message.success('插入成功');
        Bus.$emit('TableProperties');
      },
    },
    destroyed() {
      window.onresize = null;
    },
  });

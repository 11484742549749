
  import { ElForm } from 'element-ui/types/form';
  import Vue from 'vue';

  export default Vue.extend({
    name: 'ComponentAdd',
    props: ['visible'],
    data() {
      return {
        visible2: this.visible, // 希望父组件能用.sync修饰符，所以要用visible2做“中转”
        formLabelWidth: '70px',
        form: {
          name: '1',
          qsCategory: '',
          qsTypeTable: '',
          quantity: 1,
        },
        rules: {
          name: [
            { required: true, message: '请输入构件名称', trigger: 'blur' },
          ],
          qsCategory: [
            { required: true, message: '请选择构件类别', trigger: 'change' },
          ],
          qsTypeTable: [
            { required: true, message: '请选择构件类型', trigger: 'change' },
          ],
        },
      };
    },
    computed: {
      secondTree(): any {
        return this.$store.state.operand.categorySecondTree;
      },
      qsTypeList(): any[] {
        let ret = [];
        // 要根据用户选中的类别，来判断类型数组是啥
        this.secondTree.forEach((treeNode) => {
          if (treeNode.category.code === this.form.qsCategory) {
            ret = treeNode.category.nodes;
          }
        });
        return ret;
      },
      componentsTreeData(): any {
        return this.$store.state.operand.componentsTree;
      },
    },
    watch: {
      visible() {
        this.visible2 = this.visible;
        this.form.name = '1';
        this.componentsTreeData.forEach((e) => {
          e.elements.forEach((element) => {
            if (this.form.name === element.name) this.form.name = (Number(this.form.name) + 1).toString();
          });
        });
      },
      secondTree(list) {
        if (list && list.length > 0 && !this.form.qsCategory) this.form.qsCategory = list[0].category.code;
      },
      qsTypeList(list) {
        if (list && list.length > 0 && !this.form.qsTypeTable) this.form.qsTypeTable = list[0].code;
      },
      visible2() {
        this.$emit('update:visible', this.visible2);
      },
    },
    methods: {
      async addCom() {
        await (this.$refs.form as ElForm).validate();
        await this.$store.dispatch('addComponent', this.form);
        this.visible2 = false;
      },
    },
  });


  import Vue from 'vue';
  import {
    getCalcSelection,
    getQSCalcSelection,
    putCalcSelection,
  } from '@/api/project';
  import { mapGetters } from 'vuex';
  import { Tree } from 'element-ui';
  import MeasurementTable from '../MeasurementTable/Index.vue';

  export default Vue.extend({
    name: 'Measurement',
    props: ['isbbin', 'visible'],
    components: {
      MeasurementTable,
    },
    data() {
      return {
        handleSaveLoading: false,
        scopeChecked: [] as string[],
        scopeData: [] as any[],
        floors: [] as any[],
        floorNumberChecked: [] as number[],
        componentTypes: [] as any[],
        componentTypesCheckedCount: 0, // todo: 本质上应该是从Element-UI内部来的计算属性，但是技术上应该没办法响应式了吧？时间紧迫，我还没试。
        activeindex: 0,
        scopshow: true,
        tableshow: false,
        actiwith: '700px',
        checked: '',
        tableOnly: false,
        startBtnText: '开始计算',
      };
    },
    computed: {
      ...mapGetters({
        buildingId: 'buildingId',
      }),
      visibleInner: {
        get(): boolean {
          return this.visible;
        },
        set(val: boolean) {
          this.$emit('update:visible', val);
        },
      },
      buttonDisabled(): boolean {
        if (this.scopeChecked.length > 0 && this.floorNumberChecked.length > 0
          && (this.componentTypeCheckedAll || this.componentTypeIndeterminate)) {
          return Boolean(this.handleSaveLoading || this.$store.getters.projectReadonly || this.$store.state.buildingModule.progress);
        }
        return true;
      },
      floorIndeterminate(): boolean {
        const len = this.floorNumberChecked.length;
        return len > 0 && len < this.floorNumbers.length;
      },
      floorCheckedAll: {
        get(): boolean {
          return this.floorNumberChecked.length === this.floors.length;
        },
        set(val: boolean) {
          this.floorNumberChecked = val ? this.floorNumbers : [];
        },
      },
      floorNumbers(): number[] {
        return this.floors.map((d) => d.number);
      },
      componentTypeLeaves(): any[] {
        const ret: any[] = [];
        this.componentTypes.forEach((parent) => {
          parent.nodes.forEach((child) => {
            ret.push(child);
          });
        });
        return ret;
      },
      componentTypeCheckedAll: {
        get(): boolean {
          return this.componentTypesCheckedCount === this.componentTypeLeaves.length;
        },
        set(val: boolean) {
          if (val) {
            (this.$refs.componentTypeTree as Tree).setCheckedNodes(this.componentTypeLeaves);
            this.componentTypesCheckedCount = this.componentTypeLeaves.length;
          } else {
            (this.$refs.componentTypeTree as Tree).setCheckedNodes([]);
            this.componentTypesCheckedCount = 0;
          }
        },
      },
      componentTypeIndeterminate(): boolean {
        return this.componentTypesCheckedCount > 0 && this.componentTypesCheckedCount < this.componentTypeLeaves.length;
      },
    },
    watch: {
      visibleInner(val) {
        if (val) {
          this.initialize();
        }
      },
    },
    created() {
      if (this.isbbin) {
        this.startBtnText = '开始汇总';
        this.scopshow = false;
        this.tableshow = true;
        this.actiwith = 'calc(100% - 50px)';
      } else {
        this.startBtnText = '开始计算';
        this.activeindex = 0;
        this.scopshow = true;
        this.tableshow = false;
        this.actiwith = '700px';
      }
    },
    methods: {
      async initialize() {
        this.componentTypes = await this.$api.Quantity.getAllCategories();
        let count = 0;
        // ASSERT: 树深度为2
        this.componentTypes.forEach((e) => {
          count += e.nodes.length;
        });
        this.componentTypesCheckedCount = count;
        this.floors = this.$store.state.buildingModule.floorList; // 获取楼层
        this.scopeData = await getCalcSelection();
        this.initializeChecked();
      },
      async initializeChecked() {
        try {
          const data = await getQSCalcSelection(this.buildingId); // 获取选中值
          this.floorNumberChecked = data.floors;
          const componentTypeCheckedVals = data.qsTypes;
          const checkedKeywords: any[] = [];
          this.componentTypes.forEach((parent) => {
            parent.nodes.forEach((child) => {
              if (componentTypeCheckedVals.includes(child.val)) {
                checkedKeywords.push(child.keyword);
              }
            });
          });
          (this.$refs.componentTypeTree as any).setCheckedKeys(checkedKeywords);
          this.scopeChecked = data.qsMajorScope;
        } catch (err) {
          this.floorCheckedAll = true;
          this.componentTypeCheckedAll = true;
          this.scopeChecked = this.scopeData.map((e) => e.val);
        }
      },
      handleCheckChange() {
        this.componentTypesCheckedCount = (this.$refs.componentTypeTree as any).getCheckedKeys(true).length;
      },
      async handleSave() {
        this.handleSaveLoading = true;
        const types = (this.$refs.componentTypeTree as any).getCheckedNodes(true).map((e) => e.val);
        const data = {
          qsTypes: types as string[],
          floors: this.floorNumberChecked,
          buildingId: this.buildingId as string,
          qsMajorScope: this.scopeChecked,
          isOnlyTable: this.tableOnly,
        };
        try {
          await putCalcSelection(data);
          this.$emit('save');
          // todo: 这是什么逻辑？
          setInterval(() => {
            this.handleSaveLoading = false;
          }, 1000);
        } catch {
          this.$message({
            message: '计算失败',
            type: 'warning',
          });
        } finally {
          this.visibleInner = false;
        }
      },
      switchcc(index) {
        this.activeindex = index;
        if (index === 0) {
          this.scopshow = true;
          this.tableshow = false;
          this.actiwith = '700px';
        } else {
          this.scopshow = false;
          this.tableshow = true;
          this.actiwith = 'calc(100% - 50px)';
        }
      },

    },
  });

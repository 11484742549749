
import Vue from 'vue';
  import echarts from 'echarts';

  export default Vue.extend({
    name: 'QuantityChartDoughnut',
    props: ['className', 'config'],
    data() {
      return {
        width: '100%',
        chart: null as any,
      };
    },
    computed: {
      showError(): boolean {
        return this.$store.state.buildingModule.showError;
      },
    },
    watch: {
      'config.data': function () {
        this.initChart();
        setTimeout(() => {
          this.chart.resize();
        }, 100);
      },
      showError() {
        setTimeout(() => {
          window.dispatchEvent(new Event('resize')); // 触发window的resize
        }, 20);
      },
    },
    mounted() {
      this.initChart();
      window.addEventListener('resize', this.chartResizeHandler); // todo: 函数节流
    },
    beforeDestroy() {
      if (!this.chart) {
        return;
      }
      this.chart.dispose();
      this.chart = null;
    },
    methods: {
      chartResizeHandler() {
        if (this.chart) {
          this.chart.resize();
        }
      },
      initChart() {
        const { data, unit } = this.config;
        let { total } = this.config;

        this.chart = echarts.init(this.$refs.echart, 'macarons');
        const option = {
          tooltip: {
            trigger: 'item',
            formatter: `{b} <br/> {d}% {c}(${unit})`,
          },
          color: total === 0 ? ['#e4e4e4'] : this.config.bgColor,
          legend: {
            orient: 'vertical',
            top: 'center',
            show: false,
            right: '2%',
            itemWidth: 14,
            itemHeight: 14,
            formatter(name) {
              let target = 0;
              for (let i = 0, l = data.length; i < l; i++) {
                total += data[i].value;
                if (data[i].name === name) {
                  target = data[i].value;
                }
              }
              let d: any = ((target / total) * 100).toFixed(2);
              if (target === 0) {
                d = 0;
              }
              const arr = [
                `{a|${name}}`,
                `{b|${d}%}`,
                `{c|${target}${unit}}`,
              ];
              return arr.join('');
            },
            textStyle: {
              rich: {
                a: {
                  width: 40,
                },
                b: {
                  width: 60,
                  align: 'right',
                  padding: [0, 10, 0, 10],
                },
                c: {
                  width: 80,
                  align: 'right',
                  color: '#999',
                },
              },
            },
          },
          grid: {
            left: 0,
          },
          graphic: [
            {
              type: 'text',
              left: 'center',
              top: '40%',
              style: {
                text: this.config.name,
                fill: '#333',
                fontSize: 14,
                textAlign: 'center',
              },
            },
            {
              type: 'text',
              left: 'center',
              top: '55%',
              style: {
                text: total,
                fill: '#333',
                fontSize: 20,
                textAlign: 'center',
              },
            },
          ],
          series: [
            {
              name: this.config.name,
              type: 'pie',
              radius: ['55%', '70%'],
              avoidLabelOverlap: false,
              label: {
                normal: {
                  show: false,
                  position: 'center',
                },
                emphasis: {
                  show: false,
                  textStyle: {
                    fontSize: '30',
                    fontWeight: 'bold',
                  },
                },
              },
              labelLine: {
                normal: {
                  show: false,
                },
              },
              data,
            },
          ],
        };
        this.chart.setOption(option);
        // 工程量饼图切换模型
        this.chart.on('click', (param) => {
          if (param.data.category) {
            this.seeModel(param.data.category);
          }
        });
      },
      seeModel(val) { // 模型反查
        const { calculateType } = this.$store.state.buildingModule;
        const { allComList } = this.$store.state.modelModule;
        let { nodes } = calculateType.find((r) => Number(r.val) === val);
        nodes = nodes.map((e) => Number(e.val));
        const compIds: any[] = [];
        allComList.qsTypes.forEach((e, q) => {
          const vals = nodes.find((r) => r === e);
          if (vals) {
            compIds.push(allComList.viewElementIds[q]);
          }
        });
        this.$store.commit('switchingModel', { compIds, projectCount: true });
        this.$store.commit('filterGroupCheck', [0, [false, '全部楼层']]);
      },
    },
  });


import Vue from 'vue';
  import {
    getReportBuilding,
  } from '@/api/project';
  import { mapGetters } from 'vuex';

  export default Vue.extend({
    name: 'QuantityContentCount',
    props: ['val', 'searchVal'],
    data() {
      return {
        engineeringInfor: {} as any,
        enginLineOne: [] as any[],
        enginLineTwo: [] as any[],
        enginLineThree: [] as any[],
        enginLineFour: [] as any[],
        enginLineFi: [] as any[],
        enginLineSi: [] as any[],
        enginLineSe: [] as any[],
        enginLineEi: [] as any[],
        enginLineNi: [] as any[],
        enginLineTe: [] as any[],
        eng: [] as any[],
      };
    },
    computed: {
      ...mapGetters({
        buildingId: 'buildingId',
      }),
    },
    mounted() {
      this.getReportBuilding();
    },
    methods: {
      filterKeyLight(text1, text2) { // 过滤高亮关键字
        text1 = text1.toString();
        text2 = text2.toString();
        if (!this.searchVal) return text1 + text2;
        const result1 = text1.replace(new RegExp(this.searchVal, 'g'), `<span style="background-color:#fcc360;">${this.searchVal}</span>`);
        const result2 = text2.replace(new RegExp(this.searchVal, 'g'), `<span style="background-color:#fcc360;">${this.searchVal}</span>`);
        return result1 + result2;
      },
      rem() {
        this.eng = [];
        this.enginLineOne = [];
        this.enginLineTwo = [];
        this.enginLineThree = [];
        this.enginLineFour = [];
        this.enginLineFi = [];
        this.enginLineSi = [];
        this.enginLineSe = [];
        this.enginLineEi = [];
        this.enginLineNi = [];
        this.enginLineTe = [];
      },
      async getReportBuilding() {
        this.rem();
        const option = {
          reportId: 11,
          buildingId: this.buildingId,
        };
        const res = await getReportBuilding(option);
          this.engineeringInfor = res;
          let trVal = 5;
          if (JSON.stringify(res) === '{}') {
            trVal = 0;
          }
          this.$emit('currentTab', this.$refs.tab, trVal);
          this.enginLineOne.push(
            {
              name: '工程名称：',
              value: this.engineeringInfor.buildingName,
            },
            {
              name: '工程类型：',
              value: this.engineeringInfor.buildingCategory,
            },
            {
              name: '结构类别：',
              value: this.engineeringInfor.structTypeTxt,
            },
          );
          this.enginLineTwo.push(
            {
              name: '地上层数：',
              value: this.engineeringInfor.overgroundFloorCount,
            },
            {
              name: '地下层数：',
              value: this.engineeringInfor.undergroundFloorCount,
            },
            {
              name: '建筑面积(m²)：',
              value: this.engineeringInfor.floorArea,
            },
          );
          this.enginLineThree.push(
            {
              name: '抗震等级：',
              value: this.engineeringInfor.antiEarthQuackTypeTxt,
            },
            {
              name: '环境类别：',
              value: this.engineeringInfor.environmentTypeTxt,
            },
          );
          this.enginLineFour.push(
            {
              name: '钢筋总重(T)：',
              value: this.engineeringInfor.physicalRebarWeightSum,
            },
            {
              name: '措施筋总重(T)：',
              value: this.engineeringInfor.measureRebarWeightSum,
            },
            {
              name: '单方含量(T/m²)：',
              value: Number(this.engineeringInfor.rebarWeightPerSquare) / 1000,
            },
          );
          this.enginLineFi.push(
            {
              name: '设计单位：',
              value: this.engineeringInfor.designCompany,
            },
          );
          this.enginLineSi.push(
            {
              name: '建设单位：',
              value: this.engineeringInfor.ownerCompany,
            },
          );
          this.enginLineSe.push(
            {
              name: '施工单位：',
              value: this.engineeringInfor.constructionCompany,
            },
          );
          this.enginLineEi.push(
            {
              name: '监理单位：',
              value: this.engineeringInfor.supervisorCompany,
            },
          );
          this.enginLineNi.push(
            {
              name: '编制人：',
              value: '',
            },
          );
          this.enginLineTe.push(
            {
              name: '编制日期：',
              value: (this as any).$moment(new Date()).format('YYYY-MM-DD'),
            },
          );
          this.eng.push(this.enginLineOne, this.enginLineTwo, this.enginLineThree, this.enginLineFour, this.enginLineFi, this.enginLineSi, this.enginLineSe, this.enginLineEi, this.enginLineNi, this.enginLineTe);
      },
    },
  });


  import Vue from 'vue';
  import {
    getBuildingQuantityDetails,
  } from '@/api/project';
  import { mapGetters } from 'vuex';
  import QuantityChartDoughnut from './QuantityChartDoughnut.vue';

  export default Vue.extend({
    name: 'QuantityChart',
    components: {
      QuantityChartDoughnut,
    },
    data() {
      return {
        doughnutconfig: [
          {
            title: '预制类别占比',
            height: '50%',
            name: '总量（个）',
            total: 0,
            unit: '个',
            bgColor: [],
            data: [],
          },
          {
            title: '模板类别占比',
            height: '50%',
            name: '总量（㎡）',
            total: 0,
            unit: '㎡',
            bgColor: [],
            data: [],
          },
          {
            title: '钢筋分类占比',
            height: '50%',
            name: '总量（kg）',
            total: 0,
            unit: 'kg',
            bgColor: [],
            data: [],
          },
          {
            title: '混凝土类别占比',
            height: '50%',
            name: '总量（m³）',
            total: 0,
            unit: 'm³',
            bgColor: [],
            data: [],
          },
        ] as any,
        total: 0,
        bgColor: [
          {
            name: '基础',
            color: '#01cbd0',
          },
          {
            name: '柱',
            color: '#5e41c4',
          },
          {
            name: '梁',
            color: '#f06de2',
          },
          {
            name: '墙',
            color: '#e73377',
          },
          {
            name: '板',
            color: '#f8d064',
          },
          {
            name: '楼梯',
            color: '#A2E65C',
          },
          {
            name: '其它',
            color: '#FAFF5D',
          },
        ],
        quantityTypes: [51, 211, 55, 41],
      };
    },
    mounted() {
      this.changeVal();
    },
    computed: {
      switchingModel(): any {
        return this.$store.state.buildingModule.switchingModel;
      },
      ...mapGetters({
        buildingId: 'buildingId',
      }),
    },
    watch: {
      switchingModel(val) {
        if (JSON.stringify(val) === '{}') {
          window.dispatchEvent(new Event('resize')); // 触发window的resize
        }
      },
    },
    methods: {
      changeVal() {
        for (let c = 0; c < 4; c++) {
          this.getBuildingQuantityDetails(this.quantityTypes[c]).then((data) => {
            if (data.length === 0) {
              data.push({ value: 0, name: '总量' });
            }
            data.forEach((e) => {
              const val = this.bgColor.find((item) => item.name === e.name);
              if (val) {
                this.doughnutconfig[c].bgColor.push(val.color);
              }
            });
            this.doughnutconfig[c].data = data;
            this.doughnutconfig[c].total = (this.total as any).toFixed(2) - 0;
            this.doughnutconfig[c].height = this.doughnutconfig[0].total === 0 ? '50%' : '80%';
          });
        }
      },
      async getBuildingQuantityDetails(val) {
        try {
          const res = await getBuildingQuantityDetails({
            // TODO: 刷新页面builingId丢失
            buildingId: this.buildingId || sessionStorage.getItem('bid'),
            quantityType: val,
          });
          const data: any[] = [];
          this.total = 0;
          if (res.length > 0) {
            const t = val === 55 && res[0].items.some((e) => e.quantity >= 10000);
            if (t) {
              this.doughnutconfig[2].unit = 't';
              this.doughnutconfig[2].name = '总量（t）';
            }
            res[0].items.forEach((e) => {
              if (t) e.quantity /= 1000;
              data.push({ value: e.quantity.toFixed(2) - 0, name: e.categoryName, category: e.category });
              this.total += e.quantity;
            });
          }
          return data;
        } catch (err) {
          this.$message.error('获取失败');
          return [];
        }
      },
    },
  });


import FileSaver from 'file-saver';
import xlsxTab from 'xlsx';
import XLSX from 'yxg-xlsx-style'; // todo: 命名

const excel = {
    /**
     * @param downloadTab 原生table
     * @param currentCount 元素
     * @param title 下载名称
    */
    excelDownload(downloadTab, currentCount, title) {
        if (downloadTab) {
            downloadTab.rows[1].cells[0].colSpan -= 1;
            const s2ab = (s) => {
                const buf = new ArrayBuffer(s.length);
                const view = new Uint8Array(buf);
                for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
                return buf;
            };
            const letter = [];
            for (let i = 0; i < 26; i++) {
                letter.push(String.fromCharCode((65 + i)));
            }
            const vb = xlsxTab.utils.table_to_book(downloadTab);
            const borderAll = { // 单元格外侧框线
                top: {
                    style: 'thin',
                },
                bottom: {
                    style: 'thin',
                },
                left: {
                    style: 'thin',
                },
                right: {
                    style: 'thin',
                },
            };
            const excelData = vb.Sheets.Sheet1;
            const keys = Object.keys(excelData);
            keys.forEach((k) => {
                if (k.indexOf('!') === -1) {
                    excelData[k].s = { // td加边框
                        border: borderAll,
                    };
                    if (excelData[k].v.indexOf('title') > -1) { // 标题
                        excelData[k].v = excelData[k].v.replace(/title/, '');
                        excelData[k].s = {
                            font: {
                                sz: 18,
                                bold: true,
                            },
                            alignment: {
                                horizontal: 'center',
                            },
                            border: '',
                        };
                    }
                    if (excelData[k].v.indexOf('no') > -1) { // 去除边框
                        excelData[k].v = excelData[k].v.replace(/no/, '');
                        excelData[k].s = {
                            border: '',
                        };
                    }
                    if (excelData[k].v.indexOf('th') > -1) { // 表头加粗
                        excelData[k].v = excelData[k].v.replace(/th/, '');
                        excelData[k].s = {
                            font: {
                                bold: true,
                            },
                            alignment: {
                                horizontal: 'center',
                                vertical: 'center',
                            },
                            border: borderAll,
                        };
                    }
                    if (excelData[k].v.indexOf('center') > -1) { // 居中
                        excelData[k].v = excelData[k].v.replace(/center/, '');
                        excelData[k].s = {
                            alignment: {
                                horizontal: 'center',
                                vertical: 'center',
                            },
                            border: borderAll,
                        };
                    }
                    if (excelData[k].v.indexOf('<br/>') > -1) { // 去除换行
                        excelData[k].v = excelData[k].v.replace(/<br\/>/g, '');
                        excelData[k].s = {
                            alignment: {
                                vertical: 'center',
                                wrapText: true,
                            },
                            border: borderAll,
                        };
                    }
                    if (excelData[k].v.indexOf('right') > -1) { // 向右对齐
                        excelData[k].v = excelData[k].v.replace(/right/, '') - 0;
                        excelData[k].t = 'n';
                        excelData[k].s = {
                            alignment: {
                                vertical: 'center',
                            },
                            border: borderAll,
                        };
                    } else if (excelData[k].v.indexOf('left') > -1) { // 向左对齐
                        excelData[k].v = excelData[k].v.replace(/left/, '');
                    }
                }
            });
            if (currentCount === 'QuantityCivilCount') {
                excelData['!cols'] = [{ wpx: 50 }, { wpx: 150 }, { wpx: 150 }, { wpx: 200 }, { wpx: 100 }, { wpx: 100 }];
            } else if (downloadTab.rows.length > 3) {
                const rows = [...downloadTab.rows].sort((a, b) => a.children.length - b.children.length);
                excelData['!cols'] = [...(rows[rows.length - 1].children)].map(() => ({ wpx: 80 }));
            } else {
                excelData['!cols'] = [{ wpx: 100 }, { wpx: 100 }, { wpx: 100 }, { wpx: 100 }, { wpx: 100 }, { wpx: 100 }, { wpx: 100 }, { wpx: 100 }, { wpx: 100 }];
            }

            const lastVal = excelData['!ref'].substr(4); // 结束值
            const lastIdx = letter.indexOf(excelData['!ref'][3]); // 结束值的索引
            for (let d = 0; d < letter.length; d++) { // 补充被合并的单元格的样式
                const val = letter[d];
                for (let i = 3; i <= Number(lastVal); i++) {
                    if (val + i in excelData === false) {
                        excelData[val + i] = {};
                        excelData[val + i].s = {
                            border: borderAll,
                        };
                    }
                }
                if (d === lastIdx) {
                    break;
                }
            }
            const vbout = XLSX.write(vb, { bookType: 'xlsx', bookSST: false, type: 'binary' });
            try {
                FileSaver.saveAs(new Blob([s2ab(vbout)], { type: 'application/octet-stream' }), `${title}.xlsx`);
            } catch (e) {
                console.log(e);
            }
        }
    },
};
export default excel;

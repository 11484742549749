
  import Vue from 'vue';

  export default Vue.extend({
    props: ['name', 'initialData'],
    data() {
      return {
        data: null as any,
        secondTh: [] as any[],
        h: 0,
      };
    },
    watch: {
      initialData(val) {
        if (val) {
          this.secondTh = [];
          this.h = 0; // 总列数
          val.headers.forEach((r) => {
            if (r.subHeaders !== null) {
              // eslint-disable-next-line no-plusplus
              for (let i = 0; i < r.subHeaders.length; i++) {
                this.secondTh.push(r.subHeaders[i]);
              }
              this.h += r.subHeaders.length;
            } else {
              this.h += 1;
            }
          });
          this.data = val;
          this.$nextTick(() => {
            this.$emit('currentTab', this.$refs.hideTab, this.data.rows.length);
          });
        }
      },
    },
  });

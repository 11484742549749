
import Vue from 'vue';
import Bus from '@/bus.js';
import { Tree } from 'element-ui';
import { BillTreeNode } from '@/api/types';

  export default Vue.extend({
    name: 'OperandList',
    props: {
      name: {
          type: String,
      },
    },
    data() {
      return {
        isKeyword: false,
        keyword: '',
        chapter: '', // 章节列表
        listData: [] as BillTreeNode[],
        treeData: [] as BillTreeNode[],
      };
    },
    watch: {
      keyword(val): any {
        (this.$refs.tree as Tree).filter(val);
      },
      isKeyword(val): any {
        if (val) {
          this.treeData = [];
          this.listData.forEach((element) => {
            this.treeData = [...this.treeData, ...element.nodes];
          });
        }
      },
    },
    async created() {
      this.listData = await this.$api.Bill.getAllBillLibs({ libId: '1' });
      this.listData.forEach((element) => {
        this.treeData = [...this.treeData, ...element.nodes];
      });
    },
    methods: {
      filterNode(value, data) {
        if (!value) return true;
        return data.name.indexOf(value) !== -1;
      },
      selectChapter(e) {
        if (e) {
          this.listData.filter((item) => item.id === e).forEach((element) => {
            this.treeData = [];
            this.treeData = [...this.treeData, ...element.nodes];
          });
        } else {
          this.treeData = [];
          this.listData.forEach((element) => {
            this.treeData = [...this.treeData, ...element.nodes];
          });
        }
      },
      createFilter(queryString) {
        return (restaurant) => (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      },
      /** 节点双击点击事件 */
      handleDoubleClick(e) {
        if (!this.$store.getters.projectReadonly) {
          if (e.isLeaf) {
            if (this.name === 'bill') {
              this.$emit('addListItem', e);
            } else {
              Bus.$emit('addListItem', e);
            }
          }
        }
      },
    },
  });


import Vue from 'vue';
  import {
    putReportCivil,
  } from '@/api/project';
  import { mapGetters } from 'vuex';

  export default Vue.extend({
    name: 'QuantityCivilCount',
    props: ['val', 'searchVal', 'activeC', 'hintBg'],
    data() {
      return {
        chooseActive: -1,
        headers: [] as any[],
        headersby: [] as any[],
        tableData: [] as any[],
        tableDataby: [] as any[],
        name: '',
        secondTh: [] as any[],
        cells: [] as any[],
        tableB: [] as any[],
        tableS: [] as any[],
        loading: true,
        pageSize: 0,
      };
    },
    created() {
      this.putReportCivil();
    },
    computed: {
      ...mapGetters({
        buildingId: 'buildingId',
      }),
    },
    watch: {
      val() {
        this.putReportCivil();
      },
      hintBg() {
        this.tableData.forEach((element) => { // 提示高亮
          if (element.bg === false) {
            element.bg = true;
          } else if (element.bg) {
            element.bg = false;
          }
        });
      },
    },
    methods: {
      filterKeyLight(text) { // 过滤高亮关键字
        if (text === null || text === undefined) {
          text = '';
        } else {
          text = text.toString();
        }
        if (!this.searchVal) return text;
        const result = text.replace(new RegExp(this.searchVal, 'g'), `<span style="background-color:#fcc360;">${this.searchVal}</span>`);
        return result;
      },
      async putReportCivil() {
        this.secondTh = [];
        this.tableB = [];
        this.tableS = [];
        const { reportId } = this.val;
        const data = {
          reportId,
          buildingId: this.buildingId,
          floorNames: this.val.floorNames,
          qsTypes: this.val.type,
          reportScopes: this.val.reportScopes,
        };
        this.loading = true;
        this.tableData = [];
        try {
          const res = await putReportCivil(data);
            if (reportId !== this.val.reportId) return;
          res.rows.forEach((element) => { // 默认没选中
            element.current = false;
          });
          this.tableData = JSON.parse(JSON.stringify(res.rows));
          const first = res.rows.find((e) => e.peggable);
          if (first) {
            res.rows.forEach((element) => {
              const { tier } = first;
              const reg = new RegExp(`^${tier}`);
              if (reg.test(element.tier)) {
                element.bg = false;
              }
            });
          }
          this.headers = res.headers;
          // 删除不在选中范围内的工程量
          let txt = ['汇总工程量', '模型工程量', '表格工程量'];
          txt = txt.filter((item, idx) => !this.val.reportScopes.some((ele) => ele - 1 === idx));
          txt.forEach((e) => {
            const headersIdx = this.headers.findIndex((i) => i.txt === e);
            if (headersIdx > -1) this.headers.splice(headersIdx, 1);
          });
          this.$emit('initialData', res); // 导出excel的值
          if (this.tableData.length === 0) {
            this.tableB = [];
          } else if (this.tableData.length > 0) {
            this.pageSize = 1;
            const a = 23 - this.tableData.length;
            let cells: any[] = [];
            let b = 0;
            this.headers.forEach((r) => {
              if (r.subHeaders === null) {
                b += 1;
              } else if (r.subHeaders !== null) {
                b += r.subHeaders.length;
              }
            });
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < a; i++) {
              cells = [];
              // eslint-disable-next-line no-plusplus
              for (let r = 0; r < b; r++) {
                cells.push({ value: '', rowSpan: 1, colSpan: 1 });
              }
              this.tableData.push({ cells });
            }
            this.pageSize = Math.ceil(this.tableData.length / 23);
            this.tableB.push(this.tableData);
          }
          this.headers.forEach((r) => {
            if (r.subHeaders !== null) {
              // eslint-disable-next-line no-plusplus
              for (let i = 0; i < r.subHeaders.length; i++) {
                this.secondTh.push(r.subHeaders[i]);
              }
            }
          });
          this.headersby = this.headers;
          this.tableDataby = this.tableData;
        } finally {
          this.loading = false;
        }
      },
      choose(tr, idx, td, tdIdx, dblclick) { // 反查模型选中高亮
        if (tr.peggable) {
            this.$emit('checkModel', { data: tr.conditions, dblclick });
            const tier = `${tr.tier}-`;
            const reg = new RegExp(`^${tier}`);
            this.tableData.forEach((element) => {
              if (reg.test(element.tier) && element.cells[0].tierFatherName === tr.cells[0].tierFatherName) { // 匹配层级开头与选中一样的行高亮成块状
                element.current = true;
              } else {
                element.current = false;
              }
            });
            tr.current = true;
            return true;
        }
        if (!td.peggable) { return false; }
        let rowSpanVal = 1; // 跨行
        tr.cells.forEach((d) => {
          if (d.rowSpan > 1 && d.peggable) {
            rowSpanVal = d.rowSpan;
          }
        });
        if (rowSpanVal) {
          this.tableData.forEach((e, i) => {
            if (i >= idx && i <= idx + rowSpanVal - 1) {
              e.cells.forEach((d, didx) => {
                if (rowSpanVal === 1) {
                  if (didx >= tdIdx) {
                    this.$set(this.tableData[i].cells[didx], 'current', true);
                  }
                } else {
                  this.$set(this.tableData[i].cells[didx], 'current', true);
                }
              });
            } else {
              e.cells.forEach((d, didx) => {
               this.$set(this.tableData[i].cells[didx], 'current', false);
              });
            }
          });
        }
        this.$emit('checkModel', { data: td.conditions, dblclick });
        return true;
      },
    },
  });

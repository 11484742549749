
import Vue from 'vue';

  export default Vue.extend({
    name: 'ComponentEdit',
    props: ['component'],
    data() {
      return {
        visible: true,
        formLabelWidth: '70px',
        form: {
          id: this.component.id,
          name: this.component.name,
          qsCategory: 0,
          qsTypeTable: 0,
        },
      };
    },
    computed: {
      secondTree(): any {
        return this.$store.state.operand.categorySecondTree;
      },
      // 类型列表
      qsTypeList(): any {
        if (this.secondTree.length === 0) {
          return [];
        }
        return this.secondTree.find((item) => item.category.code === this.form.qsCategory).category.nodes;
      },
    },
    async mounted() {
      // 根据二级分类去寻找对应的一级分类
      this.secondTree.forEach((tree) => {
        const { nodes, code } = tree.category;
        nodes.forEach((node) => {
          if (node.code === this.component.qsTypeTable) {
            this.form.qsCategory = code;
          }
        });
      });
      this.form.qsTypeTable = this.component.qsTypeTable;
    },
    methods: {
      editCom() {
        this.$emit('finish', this.form);
      },
      cancel() {
        this.$emit('update:component', null);
      },
    },
  });

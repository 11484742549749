
import Vue from 'vue';
    import ComponentList from './ComponentList.vue';
    import MeasurementExcel from './MeasurementExcel.vue';
    import OperandList from './OperandList.vue';
    import ComponentAdd from './ComponentAdd.vue';

    export default Vue.extend({
        name: 'MeasurementTable',
        components: {
            MeasurementExcel, ComponentList, OperandList, ComponentAdd,
        },
        props: ['visible'],
        data() {
            return {
                listShow: true,
                addComponentVisible: false,
            };
        },
        computed: {
            projectReadonly(): boolean {
                return this.$store.getters.projectReadonly;
            },
        },
        mounted() {
            this.$store.dispatch('fetchCategorySecondTree');
        },
        methods: {
            listShowFunc(val) {
                this.listShow = val;
            },
        },
    });


  import Vue from 'vue';
  import { mapGetters, mapMutations } from 'vuex';
  import {
    getReportMine,
    getReportElements,
  } from '@/api/project';
  import {
    putReinforQuantiExport,
  } from '@/api/rebarSetting';
  import excel from '@/utils/excelTab';
  import QuantityChart from './QuantityChart.vue';
  import QuantityContentCount from './QuantityContentCount.vue';
  import QuantityCivilCount from './QuantityCivilCount.vue';
  import QuantitySelectFloor from './QuantitySelectFloor.vue';
  import QuantityReinforce from './QuantityReinforce.vue';
  import QuantityReinforceDetails from './QuantityReinforceDetails.vue';
  import QuantityExcelTable from './QuantityExcelTab.vue';
  import Measurement from '../Measurement/Measurement.vue';

  export default Vue.extend({
    name: 'Quantity',
    props: [],
    components: {
      QuantityChart,
      QuantityContentCount,
      QuantityCivilCount,
      QuantitySelectFloor,
      QuantityReinforce,
      QuantityExcelTable,
      Measurement,
      QuantityReinforceDetails,
    },
    data() {
      return {
        menuData: [] as any,
        currentMenuTab: 1,
        projectId: 1,
        civilId: 2,
        rebarId: 3,
        modelData: null,
        activeIndex: true,
        initialData: {} as any,
        selectFloorVisible: false,
        hintBg: false,
        downloadTab: null,
        downloadref: null,
        thCount: 1,
        tabDownload: true,
        current: [] as any[],
        getCheckedFloor: null,
        floorNums: null,
        getCheckedType: null,
        selectRebar: null,
        reportScopes: [1],
        searchVal: '',
        treeData: [] as any[],
        defaultProps: {
          children: 'nodes',
          label: 'name',
        },
        currentCount: '',
        val: {} as any,
        engineeringInfor: [] as any[],
        currentNodekey: '',
        expandedkeys: [] as any[],
        pageValue: false,
        pageS: 1,
        pageSize: 0,
        checkModelVal: null as any,
        activeC: false,
        doublePage: false,
        letter: [] as any[],
        checked: false,
        reinforcCountt: false,
        measurementVisible: false,
      };
    },
    watch: {
      pageS() {
        (this.$refs.pr as any).$parent.$refs.print.scrollTop = (this.pageS - 1) * 883;
      },
      progressSuccess(val) {
        if (val) {
          this.uploadData();
        }
      },
      currentMenuTab(val) {
        if (val === this.projectId) {
          this.switchTab(0);
        } else {
          this.initTree();
        }
      },
      showError(val) {
        if (!val) {
          window.dispatchEvent(new Event('resize')); // 触发window的resize
        }
      },
    },
    created() {
      this.getReportMine();
      this.currentCount = 'QuantityChart';
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 26; i++) {
        this.letter.push(String.fromCharCode((65 + i)));
      }
    },
    beforeDestroy() {
      this.$store.commit('switchingModel', { projectCount: false });
      this.clearElementSelection();
    },
    computed: {
      showError(): boolean {
        return this.$store.state.buildingModule.showError;
      },
      modelStatus(): number {
        return this.$store.state.buildingModule.modelStatus;
      },
      progressSuccess(): any {
        return this.$store.state.buildingModule.progressSuccess;
      },
      ...mapGetters({
        buildingId: 'buildingId',
      }),
    },
    methods: {
      ...mapMutations({ clearElementSelection: 'modelModule/clearElementSelection' }),
      getInitialData(val) {
        this.initialData = val;
      },
      currentTab(tab, dataCount) {
        if (dataCount <= 0) {
          this.tabDownload = false;
        } else {
          this.tabDownload = true;
          this.downloadTab = tab;
        }
      },
      openError() { // 打开错误页面
        this.$store.commit('setProgressSuccess', false); // 计算完成状态
        this.$store.commit('showError', true);
        this.$store.commit('calculateHint', false);
      },
      loadingModel(compIds) {
        if (this.$store.state.buildingModule.currentPattern === '2d') this.$store.commit('setCurrentPattern', '3d');
        this.$store.commit('switchingModel', { compIds, projectCount: true });
        this.$store.commit('filterGroupCheck', [0, [false, '全部楼层']]);
      },
      selectFloorColse(val) { // 关闭范围设置
        this.selectFloorVisible = val;
      },
      uploadData() {
        const n = this.currentCount;
        this.currentCount = '';
        this.$nextTick(() => {
          this.currentCount = n;
        });
      },
      selectScope(getCheckedFloor, rebar, type, sllx, floorNums) { // 选中的楼层和钢筋
        this.getCheckedFloor = getCheckedFloor;
        this.floorNums = floorNums;
        this.getCheckedType = type;
        this.selectRebar = rebar;
        this.reportScopes = sllx;
        this.val.floorNames = getCheckedFloor;
        this.val.floorNums = floorNums;
        this.val.usages = rebar;
        this.val.reportScopes = sllx;
        this.val.type = type;
        this.selectFloorVisible = false;
        this.current = getCheckedFloor.concat(rebar.concat(type));
        this.uploadData();
      },
      singDou() {
        this.activeC = this.pageValue;
        this.pageS = 1;
        if (this.pageValue) {
          this.pageSize = 0;
        } else if (!this.pageValue) {
          this.pageSize = 0;
        }
      },
      clleft() {
        if (this.pageS > 1) {
          this.pageS -= 1;
        }
      },
      clright() {
        if (this.pageS < this.pageSize) {
          this.pageS += 1;
        }
      },
      switchTab(idx) {
        this.currentNodekey = this.menuData[idx].reportId;
        this.handleNodeClick(this.menuData[idx]);
      },
      handleNodeClick(data) {
        this.activeC = false;
        this.pageValue = false;
        let name = '';
        if (data.reportId) {
          if (data.router === 'civil') {
            name = 'QuantityCivilCount';
            this.doublePage = true;
          } else if (data.router === 'rebar') {
            name = 'QuantityReinforce';
            this.doublePage = true;
          } else if (data.router === 'building') {
            name = 'QuantityContentCount';
            this.doublePage = false;
          } else if (data.router === 'buildingQuantity') {
            name = 'QuantityChart';
            this.doublePage = false;
          }
          if (data.reportId === '16') name = 'QuantityReinforceDetails';
          this.currentNodekey = data.reportId;
          this.currentCount = name;
          this.val = data;
          this.val.floorNames = this.getCheckedFloor;
          this.val.floorNums = this.floorNums;
          this.val.type = this.getCheckedType;
          this.val.usages = this.selectRebar;
          this.val.reportScopes = this.reportScopes;
          this.checkModelVal = null;
        }
      },
      async getReportMine() { // 菜单
        const res = await getReportMine();
        this.currentNodekey = res[0].reportId;
        this.menuData = res;
      },
      initTree() {
        this.treeData = this.menuData[this.currentMenuTab].nodes;
        this.treeData.forEach((dataItem) => {
          dataItem.nodes.forEach((nodeItem) => {
            this.expandedkeys.push(nodeItem.reportId);
          });
        });
        this.handleNodeClick(this.treeData[0].nodes[0]);
        this.$nextTick(() => {
          (this.$refs.tree as any).setCurrentKey(this.currentNodekey);
        });
      },
      print() {
        if (this.currentCount === 'reinforcCount') {
          this.reinforcCountt = true;
          setTimeout(() => {
            this.reinforcCountt = false;
          }, 20);
        } else {
          (this as any).$print(this.$refs.print);
        }
      },
      async pegging() { // 模型反查
        if (this.currentCount !== 'Count') {
          if (this.checkModelVal) {
            this.checkModelVal.buildingId = this.buildingId;
            try {
              const res = await getReportElements(this.checkModelVal);
              if (res) {
                const arrId: any[] = [];
                res.forEach((r) => {
                  if (r.viewElementId) {
                    arrId.push(r.viewElementId);
                  }
                });
                this.loadingModel(arrId);
              }
            } catch (err) {
              this.$message({
                message: '该构件无法进行反查',
                type: 'error',
              });
            }
          } else {
            this.$message({
              message: '请先选择构件',
              type: 'warning',
            });
            this.hintBg = true;
            setTimeout(() => {
              this.hintBg = false;
            }, 1000);
          }
        }
      },
      checkModel(data) { // 获取当前选中的内容
        this.checkModelVal = data.data;
        // eslint-disable-next-line no-unused-expressions
        data.dblclick && this.pegging();
      },
      async excelDownload() {
        if (this.val.reportId === '16') {
            const options = {
            reportId: this.val.reportId,
            buildingId: this.buildingId,
            floorNames: this.val.floorNames,
            usages: this.val.usages,
            qsTypes: this.val.type,
            reportScopes: this.val.reportScopes,
          };
          const res = await putReinforQuantiExport(options);
          const aLink = document.createElement('a');
          aLink.style.display = 'none';// 隐藏的可下载链接
          const blob = new Blob([res], { type: 'application/vnd.ms-excel' });
          aLink.href = URL.createObjectURL(blob);
          aLink.setAttribute('download', '构件钢筋明细表.xlsx'); // 设置下载文件名称
          document.body.appendChild(aLink);
          aLink.click();
          document.body.removeChild(aLink);
        } else {
          excel.excelDownload(this.downloadTab, this.currentCount, this.val.name);
        }
      },
      async jsonDownload() {
        const options = {
          reportId: '17',
          buildingId: this.buildingId,
          floorNames: this.val.floorNames,
          usages: this.val.usages,
          qsTypes: this.val.type,
          reportScopes: this.val.reportScopes,
        };
        const res = await putReinforQuantiExport(options);
        const aLink = document.createElement('a');
        aLink.style.display = 'none';// 隐藏的可下载链接
        const blob = new Blob([res], {type: 'application/octet-stream'});
        aLink.href = URL.createObjectURL(blob);
        aLink.setAttribute('download', '构件钢筋明细表.json'); // 设置下载文件名称
        document.body.appendChild(aLink);
        aLink.click();
        document.body.removeChild(aLink);
      }
    },
  });


import Vue from 'vue';

  export default Vue.extend({
    name: 'ComponentListFloor',
    computed: {
      floorList() {
        return this.$store.state.buildingModule.floorList;
      },
      currentFloorId: {
        get() {
          const { currentFloor } = this.$store.state.operand;
          return currentFloor && currentFloor.floorId;
        },
        async set(id) {
          await this.$store.dispatch('selectFloor', id);
        },
      },
    },
    async created() {
      await this.$store.dispatch('fetchFloors');
    },
  });

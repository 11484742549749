
  import Vue from 'vue';
  import jexcel from 'jexcel';
  import 'jexcel/dist/jexcel.css';
  import Bus from '@/bus';
  import {
    putfloorartxg,
    getDict,
    putCivilBills,
    postCivilBills,
    deleteCivilBills,
    getCivilBills,
    getRebarDatas,
    putRebarDatas,
    postRebarDatas,
    deleteRebarDatas,
    CivilBillsCopy,
    RebarDatasCopy,
  } from '@/api/floorSet';

  import MeasurementGraphical from './MeasurementGraphical.vue';

  export default Vue.extend({
    name: 'MeasurementExcel',
    components: {
      MeasurementGraphical,
    },
    props: ['addComponentVisible'],
    data() {
      return {
        list: ['土建算量', '钢筋算量'],
        accIndex: 0,
        artifNum: '',
        civilHeader: [
          {
            type: 'text', prop: 'billCode', width: '10%', title: '项目编码', require: true,
          },
          {
            type: 'text', prop: 'billName', width: '10%', title: '项目名称', require: true,
          },
          {
            type: 'text', prop: 'feature', width: 'auto', align: 'left', title: '项目特征', require: false,
          },
          {
            type: 'text', prop: 'unit', width: '76', title: '计量单位', align: 'left', source: [] as string[], require: true,
          },
          {
            type: 'text', prop: 'formula', width: 'auto', align: 'left', title: '工程量表达式', require: true,
          },
          {
            type: 'text', prop: 'value', width: '95', align: 'right', title: '工程量', readOnly: true, require: false,
          },
        ],
        rebarHeader: [
          {
            type: 'text', prop: 'name', width: '9%', title: '钢筋名称', require: true,
          },
          {
            type: 'text', prop: 'rebarType', width: '85', align: 'left', title: '级别', source: [] as string[], require: true,
          },
          {
            type: 'text',
            prop: 'diameter',
            width: '76',
            title: '直径',
            source: [3, 4, 5, 6, 6.5, 7, 8, 9, 10, 12, 14, 16, 18, 20, 22, 25, 28, 30, 32, 36, 38, 40, 50].map((e) => e.toString()),
            require: true,
          },
          {
            type: 'text', prop: 'usageCategory', width: '76', title: '类型', source: [] as string[], require: true,
          },
          {
            type: 'text', prop: 'formula', width: 'auto', align: 'left', title: '计算公式', require: true,
          },
          {
            type: 'text', prop: 'formulaDescription', align: 'left', width: 'auto', title: '公式描述', require: false,
          },
          {
            type: 'text', prop: 'bendAdjustment', width: '92', align: 'right', title: '弯曲调整值', require: false,
          },
          {
            type: 'text', prop: 'length', width: '92', title: '单根长度', align: 'right', readOnly: true, require: false,
          },
          {
            type: 'text', prop: 'number', title: '根数', align: 'right', width: 60, require: true,
          },
          {
            type: 'text', prop: 'jointNum', title: '搭接', width: 60, require: false,
          },
          {
            type: 'text', prop: 'lapJointTypeTxt', width: 60, title: '搭接类型', source: [] as string[], require: false,
          },
          {
            type: 'text', prop: 'weight', title: '单重(kg)', align: 'right', width: 80, readOnly: true, require: false,
          },
          {
            type: 'text', prop: 'sumWeight', title: '总重(kg)', align: 'right', width: 80, readOnly: true, require: false,
          },
        ],
        paste: false,
        pasteRows: [] as number[],
        jExcelObj: null,
        jExcelData: [] as Array<Array<string>>,
        unitList: [] as any[],
        unitValList: [0, 12, 14, 1002, 1003, 1004, 1000, 190, 1001, 1005, 1006, 1007],
        rebarTypeList: [] as any[], // 钢筋级别
        rebarUsageCategoryList: [] as any[], // 钢筋类型
        lapJointTypeList: [] as any[], // 搭接类型
        elementIds: [] as string[],
        modeleelementids: [] as any[],
        copyIds: [] as any[],
        selectionIds: [] as string[],
        handleSetIds: false,
        listingpicshow: false,
        checked: true,
        checkedpic: true,
        elementId: '',
        id: '',
        rebarSum: 0,
        isValid: true,
        jdropdown: [],
        formulaValidator: true,
        gjformulaValidator: true,
        delIds: [] as string[],
        init: false,
        ephemeralData: {
          name: '',
          data: null as any,
        }, // 没有构件时存的临时数据
      };
    },
    computed: {
      currentComponent(): any {
        return this.$store.state.operand.currentComponent;
      },
      projectReadonly(): boolean {
        return this.$store.getters.projectReadonly;
      },
      floorId(): any {
        return this.$store.state.operand.currentFloor.floorId;
      },
      jExcelOptions(): any {
        return {
          data: this.jExcelData,
          columns: this.accIndex === 0 ? this.civilHeader : this.rebarHeader,
          onchange: this.excelChanged,
          ondeleterow: this.ondeleterow,
          onbeforedeleterow: this.onbeforedeleterow,
          oncopy: this.oncopy,
          onselection: this.onselection,
          onblur: this.onblur,
          onbeforechange: this.onbeforechange,
          onbeforepaste: this.onbeforepaste,
          onpaste: this.onpaste,
          allowDeleteColumn: false,
          allowInsertColumn: false,
          text: {
            areYouSureToDeleteTheSelectedRows: '您确定要删除吗？',
          },
          contextMenu() { return false; },
        };
      },
    },
    watch: {
      async currentComponent(val) {
        if (!val) {
          this.elementId = '';
          this.id = '';
          this.elementIds = [];
          this.jExcelData = [];
          this.initExcel();
          return;
        }
        this.id = val.id;
        this.elementId = val.modelElementId;
        if (this.ephemeralData.name === 'detailedList') {
          this.addList(this.ephemeralData.data);
        } else if (this.ephemeralData.name === 'addItem') {
          if (this.accIndex === 0) {
            await this.civilExcelChange(this.ephemeralData.data[0], this.ephemeralData.data[1], this.ephemeralData.data[2]);
          } else {
            await this.rebarExcelChange(this.ephemeralData.data[0], this.ephemeralData.data[1], this.ephemeralData.data[2]);
          }
        }
        this.ephemeralData.name = '';
        this.ephemeralData.data = null;
        if (!this.init) {
          this.init = true;
          this.active(this.accIndex);
          return;
        }
        if (this.accIndex === 0) {
          this.getCivilList();
        } else {
          this.getRebarList();
        }
      },
      addComponentVisible(val) {
        if (!val && !this.currentComponent) {
          this.jExcelData = [];
          this.initExcel();
        }
      },
      checked(val) {
        this.$emit('operandListShow', val);
      },
    },
    mounted() {
      this.getDict();
      Bus.$on('addListItem', (e) => { // 添加清单库
        if (this.elementId) {
          this.addList(e);
        } else {
          this.ephemeralData.name = 'detailedList';
          this.ephemeralData.data = e;
          this.$emit('addElement');
        }
      });
      Bus.$on('TableProperties', () => {
        this.getRebarList();
      });
      this.initExcel();
    },
    methods: {
      async putfloorartxg() {
        const option = {
          id: this.id,
          quantity: this.artifNum,
        };
        await putfloorartxg(option);
      },
      // 土建钢筋切换
      active(e) {
        this.accIndex = e;
        const isCivil = this.accIndex === 0;
        this.listingpicshow = this.accIndex === 1;
        this.checkedpic = this.accIndex === 1;
        if (this.checked) this.$emit('operandListShow', isCivil);
        (this.$refs.conreinamount as any).style.height = isCivil ? '100%' : '48%';
        if (this.elementId) {
          isCivil && this.getCivilList();
          if (this.accIndex === 1) {
            Bus.$emit('getjointslpic');
            this.getRebarList();
          }
        } else {
          if (this.accIndex === 1) Bus.$emit('getjointslpic');
          this.jExcelData = [];
          this.initExcel();
        }
      },
      // 字典
      async getDict() {
        const dut = await getDict('DisplayUnitType'); // 单位
        this.unitList = this.unitValList.map((unitVal) => ({
            txt: dut.find((u) => u.val === unitVal).txt,
            val: unitVal,
          }));
        this.rebarTypeList = await getDict('RebarType'); // 钢筋级别
        this.rebarTypeList.splice(4, 4);
        this.rebarUsageCategoryList = await getDict('RebarUsageCategory'); // 钢筋类型
        this.rebarUsageCategoryList = this.rebarUsageCategoryList.filter((r) => r.txt !== '未知');
        this.lapJointTypeList = await getDict('LapJointType'); // 搭接类型
      },
      // 图形化添加
      listingpicisshow(value) {
        this.listingpicshow = value;
        if (value) {
          this.active(1);
        } else {
          (this.$refs.conreinamount as any).style.height = '100%';
          this.getRebarList();
        }
      },
      initExcel() {
        this.jExcelObj && (this.jExcelObj as any).destroy();
        const sum = this.accIndex === 0 ? 18 : 10;
        if (this.jExcelData.length < sum) {
          this.jExcelData = this.jExcelData.concat(new Array(sum - this.jExcelData.length).fill([]));
        }
        const jExcelObj = jexcel(this.$refs.spreadsheetMeasurement, this.jExcelOptions);
        Object.assign(this, { jExcelObj });
        // jexcel下拉框只能双击显示，所以需要自定义下拉框
        this.jExcelOptions.data.forEach((e, idx) => {
          this.creatArrow(`D${idx + 1}`);
          if (this.accIndex === 1) {
            this.creatArrow(`B${idx + 1}`);
            this.creatArrow(`C${idx + 1}`);
            this.creatArrow(`K${idx + 1}`);
          }
        });
        if (this.accIndex === 0 && this.selectionIds.length > 0) {
          const index = this.elementIds.findIndex((e) => e === this.selectionIds[0]);
          if (index > -1)(this.jExcelObj as any).updateSelectionFromCoords(0, index.toString(), 5, (index + this.selectionIds.length - 1).toString());
        }
      },
      creatArrow(idx) { // 创建下拉框按钮
          const select = (this.jExcelObj as any).getCell(idx);
          const span = document.createElement('span');
          span.classList.add('jexcel_arrow');
          span.classList.add('el-icon-caret-bottom');
          select.appendChild(span);
          span.addEventListener('click', this.getOptions);
          select.addEventListener('dblclick', (e) => {
            e.stopPropagation();
          });
      },
       async getOptions(e) { // 展开下拉框
         if (this.accIndex === 0) {
           (this.jdropdown as any) = JSON.parse(JSON.stringify(this.civilHeader[3].source));
         } else {
          const { cellIndex } = e.target.parentNode;
          (this.jdropdown as any) = JSON.parse(JSON.stringify(this.rebarHeader[cellIndex - 1].source));
         }
        e.target.parentNode.appendChild(this.$refs.jdropdown);
      },
      changeSelect(val) { // 修改下拉框内容
        const td = document.querySelector('.highlight-selected');
        (this.$refs.conreinamount as any).appendChild(this.$refs.jdropdown);
        (this.jExcelObj as any).setValue(td, val);
      },
      // 获取土建构件清单
      async getCivilList() {
        const res = await getCivilBills(this.elementId);
        this.jExcelData = [];
        this.elementIds = [];
        this.artifNum = res.element.quantity;
        res.billCodeDatas.forEach((e) => {
          if ((e.unit && e.unit !== -2) || e.unit === 0) e.unit = this.unitList.find((r) => r.val === e.unit).txt;
          const data: string[] = [];
          this.civilHeader.forEach((i) => {
            data.push(
              e[i.prop] || '',
            );
          });
          this.jExcelData.push(data);
          this.elementIds.push(e.id);
        });
        this.civilHeader[3].source = this.unitList.map((e) => e.txt);
        this.initExcel();
      },
      // 获取钢筋构件清单
      async getRebarList() {
        const res = await getRebarDatas(this.elementId);
        this.jExcelData = [];
        this.elementIds = [];
        let sum = 0;
        res.rebarDatas.forEach((e, idx) => {
          sum += Number(e.sumWeight);
          if (e.rebarType) e.rebarType = this.rebarTypeList.find((r) => r.val === e.rebarType).txt;
          if (e.usageCategory) e.usageCategory = this.rebarUsageCategoryList.find((r) => r.val === e.usageCategory).txt;
          e.weight = `=IF(I${idx + 1}==0,0,I${idx + 1}/I${idx + 1}*${e.weight})`; // 单重 = 单根
          e.sumWeight = `=IF(I${idx + 1}==0,0,ROUND(I${idx + 1}*L${idx + 1},3))`; // 总重 = 单根 * 单重
          if (e.diameter === 0) e.diameter = '';
          if (e.usageCategory === 0) e.usageCategory = '';
          const data: string[] = [];
          this.rebarHeader.forEach((i) => {
            data.push(
              e[i.prop],
            );
          });
          this.jExcelData.push(data);
          this.elementIds.push(e.id);
        });
        this.rebarSum = Number(sum.toFixed(3));
        this.rebarHeader[1].source = this.rebarTypeList.map((e) => e.txt);
        this.rebarHeader[3].source = this.rebarUsageCategoryList.map((e) => e.txt);
        this.rebarHeader[10].source = this.lapJointTypeList.map((e) => e.txt);
        this.initExcel();
      },
      onbeforedeleterow(instance) {
        const index = instance.jexcel.getSelectedRows().map((e) => e.rowIndex - 1);
        this.delIds = this.elementIds.filter((e, idx) => index.includes(idx));
      },
      async ondeleterow() {
        if (this.accIndex === 0) {
          await deleteCivilBills(this.delIds);
          this.getCivilList();
        } else {
          await deleteRebarDatas(this.delIds);
          this.getRebarList();
        }
        this.$message.success('删除成功');
      },
      // 复制土建
      async civilBillsCopy() {
        await CivilBillsCopy(this.copyIds);
        this.getCivilList();
      },
      // 复制钢筋
      async rebarDatasCopy() {
        await RebarDatasCopy(this.copyIds);
        this.getRebarList();
      },
      // 验证
      onbeforechange(instance, cell, col, row, val) {
        let txt = val;
        const oldVal = this.jExcelData[Number(row)][Number(col)];
        const header = this.accIndex === 0 ? 'civilHeader' : 'rebarHeader';
        if (this[header][Number(col)].require && !val) {
          txt = oldVal;
        }
        if (this[header][Number(col)].prop === 'billCode') {
          const reg = /^[a-z0-9]{9}$/;
          if (!reg.test(val)) {
            txt = oldVal;
            this.$message.error('项目编码只能输入9位及以内数字或字母');
          }
        }
        if (this[header][Number(col)].prop === 'number') {
          const reg = /^[0-9]+?$/;
          if (!reg.test(val)) {
            txt = oldVal;
            this.$message.error('根数只能输入非负整数');
          }
        }
        return txt;
      },
      // 复制
      oncopy(instance) {
        this.copyIds = [];
        instance.jexcel.getSelectedRows().forEach((c) => {
          this.copyIds.push(this.elementIds[c.rowIndex - 1]);
        });
        console.log('复制', this.copyIds);
      },
      onbeforepaste() {
        this.paste = true;
      },
      // 粘贴
      onpaste(instance, rows) {
        const pasteIndexs = [] as number[];
        rows.forEach((cells, rowIdx) => { // 粘贴到原有的内容上就调用修改接口修改多个
          const id = this.elementIds[this.pasteRows[rowIdx]]; // 粘贴id
          if (!id) pasteIndexs.push(rowIdx);
          if (!id) return;
          if (this.accIndex === 1) {
            this.rebarExcelChange(undefined, undefined, id, cells);
            return;
          }
          this.civilExcelChange(undefined, undefined, id, cells);
        });

        if (pasteIndexs.length > 0) { // 粘贴到新的地方
          this.copyIds = this.copyIds.filter((e, idx) => pasteIndexs.some((p) => p === idx));
          if (this.accIndex === 0) {
            this.civilBillsCopy();
          } else {
            this.rebarDatasCopy();
          }
        }
        this.pasteRows = [];
        this.paste = false;
      },
      onselection(instance) {
        if (this.accIndex === 1 || this.handleSetIds) return;
        const index = instance.jexcel.getSelectedRows().map((e) => e.rowIndex - 1);
        this.selectionIds = this.elementIds.filter((e, idx) => index.includes(idx));
      },
      onblur(instance) { // 表格失焦的时候会取消选中的样式，所以要再次选中
        if (this.accIndex === 1 || this.selectionIds.length === 0 || this.jExcelData.length === 0) return;
        const index = this.elementIds.findIndex((e) => e === this.selectionIds[0]);
        this.handleSetIds = true;
        instance.jexcel.updateSelectionFromCoords(0, index.toString(), 5, (index + this.selectionIds.length - 1).toString());
        setTimeout(() => {
           this.handleSetIds = false;
        }, 50);
      },
      excelChanged(instance, cell, col, row, val) {
        if (this.paste && !this.pasteRows.includes(row)) this.pasteRows.push(row);
        if (cell.classList.contains('readonly') || this.paste) return;
        if (this.accIndex === 0) {
          if (this.civilHeader[Number(col)].prop === 'unit') this.creatArrow(`D${Number(row) + 1}`);
          if (this.elementId) this.civilExcelChange(val, this.civilHeader[Number(col)].prop, this.elementIds[Number(row)]);
        } else {
          if (this.rebarHeader[Number(col)].prop === 'rebarType') {
            this.creatArrow(`B${Number(row) + 1}`);
          } else if (this.rebarHeader[Number(col)].prop === 'usageCategory') {
            this.creatArrow(`C${Number(row) + 1}`);
          } else if (this.rebarHeader[Number(col)].prop === 'lapJointTypeTxt') {
            this.creatArrow(`K${Number(row) + 1}`);
          }
          if (this.elementId) this.rebarExcelChange(val, this.rebarHeader[Number(col)].prop, this.elementIds[Number(row)]);
        }
        if (!this.elementId) {
          this.ephemeralData.name = 'addItem';
          this.ephemeralData.data = [val, this[this.accIndex === 0 ? 'civilHeader' : 'rebarHeader'][Number(col)].prop, this.elementIds[Number(row)]];
          this.$emit('addElement');
        }
      },
      civilOption(option, name, val) { // 土建接口参数
        if (name === 'feature') {
          option.features = val;
        } else {
          let unit = this.unitList.find((e) => e.txt === val);
          unit = unit ? unit.val : -2;
          option[name] = name === 'unit' ? unit : val;
        }
      },
      rebarOption(option, name, val) { // 钢筋接口参数
        option[name] = val;
        if (name === 'rebarType') {
          option[name] = this.rebarTypeList.find((r) => r.txt === val).val;
        } else if (name === 'usageCategory') {
          option[name] = this.rebarUsageCategoryList.find((cate) => cate.txt === val).val;
        } else if (name === 'lapJointTypeTxt') {
          option.lapJointType = this.lapJointTypeList.find((r) => r.txt === val).val;
        }
      },
      // 土建
      async civilExcelChange(val, name, id, cells?) {
        let option: any = { id };
        if (!id) {
          option = {
            modelElementId: this.elementId,
            billCode: '',
            billName: '',
            feature: '',
            unit: this.unitList[0].val,
            formula: '',
          };
        }
        if (name) {
          if (name === 'feature' && !id) {
            option.feature = val;
          } else {
          this.civilOption(option, name, val);
          }
        } else {
          cells.forEach((e, idx) => { // 修改多个
            if (!e && idx < 5) return;
            this.civilOption(option, this.civilHeader[idx].prop, e);
          });
        }
        try {
          if (name === 'formula') this.formulaValidator = true;
          if (id) {
            await putCivilBills(option); // 修改
          } else {
            const data = await postCivilBills(option); // 添加
            this.elementIds.push(data.id);
          }
          if (!name || name === 'formula') this.getCivilList();
        } catch {
          if (name === 'formula') this.formulaValidator = false;
        }
      },
      // 钢筋
      async rebarExcelChange(val, name, id, cells?) {
        let option: any = { id, floorId: this.floorId };
        if (!id) {
          option = {
            modelElementId: this.elementId,
            name: '',
            rebarType: '',
            diameter: '',
            usageCategory: '',
            formula: '',
            formulaDescription: '',
            bendAdjustment: '',
            length: '',
            weight: '',
            sumWeight: '',
            jointNum: '',
            lapJointType: '',
            number: '',
            floorId: this.floorId,
          };
        }
        if (name) {
          this.rebarOption(option, name, val);
        } else {
          cells.forEach((e, idx) => { // 修改多个
            if (!e && ![7, 11, 12].includes(idx)) return;
          this.rebarOption(option, this.rebarHeader[idx].prop, e);
          });
        }
        try {
          if (name === 'formula') this.gjformulaValidator = true;
          if (id) {
            await putRebarDatas(option); // 修改
          } else {
            const data = await postRebarDatas(option); // 添加
            this.elementIds.push(data.id);
          }
          if (!name || name === 'number') this.getRebarList();
        } catch {
          if (name === 'formula') this.gjformulaValidator = false;
        }
      },
      async addList(e) {
        const unit = this.unitList.find((r) => r.txt === e.unitTxt).val;
        if (this.selectionIds.length > 0) {
          this.selectionIds.forEach(async (id, idx) => {
            await putCivilBills({
              id,
              billCode: e.code,
              billName: e.name,
              unit,
            }); // 修改
            if (idx === this.selectionIds.length - 1) this.getCivilList();
          });
        } else {
          const res = await postCivilBills({
            modelElementId: this.elementId,
            billCode: e.code,
            billName: e.name,
            feature: '',
            unit,
            formula: '',
          });
          this.selectionIds = [res.id];
          this.getCivilList();
        }
      },
    },
  });
